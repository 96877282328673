import React, { Component } from 'react'
// import ExtendedForm from '../components/Forms/ExtendedForm'
import Helmet from 'react-helmet'
// import SelectServiceView from "../components/Forms/SelectServiceView";
import Layout from '../layouts/index'
import WaitlistForm from '../components/Forms/WaitlistForm';


export default class extends Component {
    state = {
        selectServiceView: false,
        domaine: null
    }

    componentDidMount() {
        console.log('location: ', this.props.location)
        if(this.props.location.state) {
            let location = this.props.location.state.from
            if(location !== 'leadform') {
                this.handleOpenSelectServiceView()
            } 
        } else {
            this.handleOpenSelectServiceView()
        }
    }

    handleOpenSelectServiceView = () => this.setState({selectServiceView: true })
    handleCloseSelectServiceView = () => this.setState({selectServiceView: false })

    handleSelectService = (d, cb) => {
        console.log('domaine: ', d)
        this.setState({domaine: d}, () => {
            console.log('state: ', this.state)
            if(cb) {
                cb()
            }
            this.forceUpdate()
        })
    }



    render() {

        let formData;
        if(this.props.location.state) formData = this.props.location.state.data

        if(this.state.domaine) formData = this.state


        return (
            <Layout location={this.props.location}>
                <div>
                    <Helmet>
                        <title>Prendre rendez en ligne | Psy-Scan Institute</title>
                        <meta name="description" content="Notre formulaire en ligne vous permet de prendre rendez-vous avec un de nos thérapeute en tout tant. Cliquez ici pour réserver votre consultation."/>
                    </Helmet>
                    <div style={{maxWidth: 1170, padding: '40px 20px', margin: 'auto'}}>
                        <div>
                            <WaitlistForm formData={formData}/>
                            {/* <ExtendedForm formData={formData} /> */}
                        </div>
                    </div>
                    {/* {this.state.selectServiceView && <SelectServiceView
                        closeSelectServiceView={this.handleCloseSelectServiceView}
                        selectService={this.handleSelectService}
                    /> } */}
                </div>
            </Layout>
        )
    }
}