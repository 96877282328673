import React, { Component } from 'react'
import axios from 'axios'

import Input from './Input'
import Select from './Select'
import Button from '../Button/Button'
import ToolTip from '../ToolTip/ToolTip'
import InsuranceInput from './insurances'
import CheckIcon from '../../images/check_circle_outline-24px.svg'
import classes from './Forms.module.css'


export default class extends Component {
    state = {
        step: 1,
        isLoading: false,
        backBtnDisabled: false,
        formData: {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            address: '',
            city: '',
            postalCode: '',
            country: 'Suisse',
            dateOfBirth: '',
            dayOfBirth: '',
            monthOfBirth: '',
            yearOfBirth: '',
            gender: 'Feminin',
            // step 2
            paymentMethod: 'assurance',
            selfPaymentMethod: 'Carte de crédit',
            avsNumber: '',
            insuranceNumber: '',
            insurerName: '',
            familyDoctor: 'medecin-famille',
            souscris: 'non',
            domaine: 'Psychiatrie (Diagnostic/Médication)',
            consultation: '',
            message: '',
            // step 3
            desiredCallTime: '9h -12h15',
            desiredConsultationTime: 'Premier disponible',
            termsAccepted: false,
            cancellationPolicy: false,
        },
        formDataErrors: {
            stepOne: {
                firstNameError:false,
                lastNameError:false,
                phoneNumberError:false,
                emailError:false,
                addressError:false,
                cityError:false,
                postalCodeError:false,
                countryError:false,
                dateOfBirthError:false,
            },
            // step 2
            stepTwo: {
                avsNumberError:false,
                insuranceNumberError:false,
                validInsuranceNumberError: false,
                insurerNameError: false,
                familyDoctorError: false,
                souscrisError: false,
                messageError:false,
            },
            // step 3
            stepThree: {
                termsAcceptedError:false,
                cancellationPolicyError: false,
            }
        },
        serverError: false
    }

    componentDidMount() {
        if(this.props.formData) {
            const data = {...this.state.formData}
            this.setState({
                ...this.state,
                formData: {
                    ...data,
                    ...this.props.formData
                }
            })
        }
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.formData && this.state.step === 1) {
            this.setState({
                ...this.state,
                formData: {
                    ...this.state.formData,
                    domaine: nextProps.formData.domaine
                }
            })
        }


    }

    handleNextStep = () => {
        this.setState({ isLoading: true, serverError: false, backBtnDisabled: true }, () => {
            setTimeout(() => {
                if(this.state.step === 1) {
                    const errors = this.checkErrorStepOne({ ...this.state.formData })
                    if(errors) {
                        const errorsData = { ...this.state.formDataErrors.stepOne }
                        this.setState({
                            ...this.state,
                            isLoading: false,
                            backBtnDisabled: false,
                            formDataErrors: {
                                ...this.state.formDataErrors,
                                stepOne: {
                                    ...errorsData,
                                    ...errors,
                                }
                            }
                        })
                    } else {
                        this.setState({step: this.state.step + 1, isLoading: false, backBtnDisabled: false})
                    }
                }
                else if(this.state.step === 2) {

                    if(this.state.formData.paymentMethod === 'assurance') {
                        const possibleErrors = {
                            messageError: this.state.formData.message.length < 1,
                            insurerNameError: this.state.formData.insurerName.length < 1,
                        }

                        const errors = this.checkErrors(possibleErrors)
                        if(errors) {
                            const errorsData = { ...this.state.formDataErrors.stepTwo }
                            this.setState({
                                ...this.state,
                                isLoading: false,
                                backBtnDisabled: false,
                                formDataErrors: {
                                    ...this.state.formDataErrors,
                                    stepTwo: {
                                        ...errorsData,
                                        ...errors,
                                    }
                                }
                            })
                        } else {
                            this.setState({step: this.state.step + 1, isLoading: false, backBtnDisabled: false})
                        }

                    } else if (this.state.formData.paymentMethod === 'soi-meme') {
                        const possibleErrors = {
                            messageError: this.state.formData.message.length < 1,
                        }
                        const errors = this.checkErrors(possibleErrors)
                        if(errors) {
                            const errorsData = { ...this.state.formDataErrors.stepTwo }
                            this.setState({
                                ...this.state,
                                isLoading: false,
                                backBtnDisabled: false,
                                formDataErrors: {
                                    ...this.state.formDataErrors,
                                    stepTwo: {
                                        ...errorsData,
                                        ...errors,
                                    }
                                }
                            })
                        } else {
                            this.setState({step: this.state.step + 1, isLoading: false, backBtnDisabled: false})
                        }
                    }
                }
                else if (this.state.step === 3) {
                    const possibleErrors = {
                        termsAcceptedError: this.state.formData.termsAccepted === false,
                        cancellationPolicyError: this.state.formData.cancellationPolicy === false
                    }
                    const errors = this.checkErrors(possibleErrors)
                    if(errors) {
                        const errorsData = { ...this.state.formDataErrors.stepThree }
                        this.setState({
                            ...this.state,
                            isLoading: false,
                            backBtnDisabled: false,
                            formDataErrors: {
                                ...this.state.formDataErrors,
                                stepThree: {
                                    ...errorsData,
                                    ...errors,
                                }
                            }
                        })
                    } else {
                        const leadData = { ...this.state.formData }
                        if(leadData.gender === 'Feminin') leadData['prefix'] = 'Mme'
                        else leadData['prefix'] = 'M.'

                        const API_URL  = 'https://us-central1-psy-scan-1f213.cloudfunctions.net/psyscan/new-lead'
                        // const API_URL  = 'https://app.sendsync.net/leads/new-lead-psy-scan'
                        axios.post(API_URL, { ...leadData })
                            .then( () => {
                                this.setState({step: this.state.step + 1, isLoading: false, backBtnDisabled: false}, () => {
                                    if(window.gtag) {
                                        window.gtag('event', 'submit', {
                                            'event_category': "Form",
                                            'event_label' : 'Psy'
                                        });
                                    }
                                })
                            })
                            .catch(err => {
                                console.log(err);
                                this.setState({isLoading: false, serverError: true, backBtnDisabled: false})
                            }
                        )
                    }
                }
            }, 1200)
        })
    }

    handleBackStep = () => {
        if(this.state.step > 1) this.setState({ step: this.state.step - 1})
    }


    handleInputChange = (e) => {
        const form = {...this.state.formData}
        if(e.target.getAttribute('name') === 'paymentMethod' && e.target.value === 'soi-meme') {
            form['familyDoctor'] = 'medecin-libre'
            form['souscris'] = false
        }
        if(e.target.getAttribute('name') === 'familyDoctor' && e.target.value === 'medecin-libre') {
            form['souscris'] = false
        }
        form[e.target.getAttribute('name')] = e.target.getAttribute('name') === 'termsAccepted' || e.target.getAttribute('name') === 'cancellationPolicy' ? e.target.checked : e.target.value
        if(e.target.getAttribute('name') === 'insuranceNumber' && e.target.value.length === 21) {
            console.log('length in', e.target.value.length)
            this.setState({
                ...this.state,
                formDataErrors: {
                    ...this.state.formDataErrors,
                    stepTwo: {
                        ...this.state.formDataErrors.stepTwo,
                        validInsuranceNumberError: true
                    }
                }
            })
        } else {
            this.setState({
                ...this.state,
                formData: {
                    ...form,
                }
            })
        }
    }

    checkErrorStepOne = (stepOneData) => {
        const obj = {
            firstNameError: stepOneData.firstName.length < 1,
            lastNameError: stepOneData.lastName.length < 1,
            phoneNumberError: stepOneData.phoneNumber.length < 1,
            emailError: stepOneData.email.length < 1,
            addressError: stepOneData.address.length < 1,
            cityError: stepOneData.city.length < 1,
            postalCodeError: stepOneData.postalCode.length < 1,
            countryError: stepOneData.country.length < 1,
            dateOfBirthError: stepOneData.dateOfBirth.length < 10,
        }
        const errors = Object.keys(obj).filter(item => obj[item] === true)

        if(errors.length === 0 ) {
            return false
        } else {
            return obj
        }
    }
    checkErrors = (obj) => {
        const errors = Object.keys(obj).filter(item => obj[item] === true)

        if(errors.length === 0 ) {
            return false
        } else {
            return obj
        }
    }

    validateInsuranceNumber = (e) => {

        if(e.target.value.length !== 20) {
            this.setState({
                ...this.state,
                formDataErrors: {
                    ...this.state.formDataErrors,
                    stepTwo: {
                        ...this.state.formDataErrors.stepTwo,
                        validInsuranceNumberError: true,
                    }
                }
            })
        } else {
            this.setState({
                ...this.state,
                formDataErrors: {
                    ...this.state.formDataErrors,
                    stepTwo: {
                        ...this.state.formDataErrors.stepTwo,
                        validInsuranceNumberError: false,
                    }
                }
            })
        }
    }

    handleSelectSavedInsurer = insurer => {
        console.log(insurer)
        if(insurer) {
            this.setState({
                ...this.state,
                formData: {
                    ...this.state.formData,
                    insurerName: insurer
                }
            })
        }
    }

    handleInsurerSearched = textSearched => this.setState({...this.state, formData: { ...this.state.formData, insurerName: textSearched}})

    handleBirthDateChange = e => {
        const name = e.target.getAttribute('name')
        const value = e.target.value
        const dayOfBirth = this.state.formData.dayOfBirth
        const monthOfBirth = this.state.formData.monthOfBirth
        const yearOfBirth = this.state.formData.yearOfBirth

        if(name === 'day') {
            if(value.length <= 2) {
                this.setState({
                    ...this.state,
                    formData: {
                        ...this.state.formData,
                        dayOfBirth:value,
                        dateOfBirth: yearOfBirth && monthOfBirth ? `${value}/${monthOfBirth}/${yearOfBirth}` : ''
                    }
                })
            }
        }
        else if (name === 'month') {
            if(value.length <= 2) {
                this.setState({
                    ...this.state,
                    formData: {
                        ...this.state.formData,
                        monthOfBirth:value,
                        dateOfBirth: yearOfBirth && dayOfBirth ? `${dayOfBirth}/${value}/${yearOfBirth}` : ''
                    }
                })
            }
        }
        else if (name === 'year') {
            if(value.length <= 4) {
                this.setState({
                    ...this.state,
                    formData: {
                        ...this.state.formData,
                        yearOfBirth:value,
                        dateOfBirth: monthOfBirth && dayOfBirth ? `${dayOfBirth}/${monthOfBirth}/${value}` : ''
                    }
                })
            }
        }
    }

    render() {
        const { step, formData: { domaine } } = this.state;
        let canbook = false;
        if(domaine === "Psychiatrie (Diagnostic/Médication)" || domaine === "Je ne sais pas ...") {
            canbook = true;
        }
        return (
            <div className={classes.FormContainer}>
                <div>
                    <FormHeader step={step} clicked={ (e) => {
                        const step =  parseInt(e.target.getAttribute('data-step'))
                        if(step < this.state.step) {
                            this.setState({step})
                        }
                    }}/>

                    { step === 1
                        ? <StepOne {...this.state}
                                   changed={ (e) => this.handleInputChange(e) }
                                   birthDateChange={this.handleBirthDateChange}
                        />
                    : null }

                    { step === 2
                        ? <StepTwo {...this.state}
                                   changed={ (e) =>  this.handleInputChange(e)}
                                   validateInsuranceNumber={(e) => this.validateInsuranceNumber(e)}
                                   selectSavedInsurer={this.handleSelectSavedInsurer}
                                   insurerSearched={this.handleInsurerSearched}
                                   canbook={canbook}
                        />
                        : null
                    }

                    { step === 3
                        ? <ReviewStep {...this.state} changed={ (e) => this.handleInputChange(e) } />
                        : null
                    }

                    { step === 4
                        ? <ThankYou />
                        : null
                    }

                </div>

                <div style={{display: 'flex', justifyContent: `${step === 1 ? 'flex-end' : 'space-between'}`, padding: '20px'}}>
                    { step === 1 || step === 4
                        ? null
                        : <Button text="Retour"
                                  styles={{backgroundColor: '#656565', border: '1px solid #656565', color: '#fff'}}
                                  clicked={this.handleBackStep} disabled={this.state.backBtnDisabled}/>
                    }

                    { step === 1
                        ? <Button text="Continuer" color="primary" clicked={this.handleNextStep} loading={this.state.isLoading} />
                        : null
                    }

                    { step === 2
                        ? <Button disabled={!canbook} text="Continuer" color="primary" clicked={this.handleNextStep} loading={this.state.isLoading} />
                        : null
                    }
                    { step === 3
                        ? <Button disabled={!canbook} text="Continuer" color="primary" clicked={this.handleNextStep} loading={this.state.isLoading} />
                        : null
                    }
                </div>
                <div style={{padding: '0 20px 20px', fontSize: '1.4rem', lineHeight: 1.3}}>
                    {this.state.serverError
                        ? <RequiredError
                            text="Votre soumission n'a pas fonctionné. Veuillez réessayer, si le problème persiste, contactez-nous au 032 721 49 33 pour prendre votre rendez-vous."/>
                        : null
                    }
                </div>
            </div>
        )
    }
}

const red = '#ff6d6b'
const green = "#00B648"
const darkBlue = "#004c92"
const blue = "#0071BC"
const orange = "#f58b00"

export const RequiredError = ({text, size}) => <div style={{color: red, fontSize: `${size ? size : 'inherit'}`}}><small>{text ? text : 'Ce champ est obligatoire'}</small></div>

const FormHeader = ({step, clicked}) => {
    let title = "Information du patient"
    if(step === 2) title = "Payement"
    if(step === 3) title= "Confirmation"
    if(step === 4) title= "Merci d'avoir soumis votre formulaire!"
    return (
        <div style={{
            display: 'flex',
            justifyContent: `${step === 4 ? 'center' : 'space-between' }`,
            alignItems:'center',
        }} className={classes.ExtendedFormHeaderContainer}>
            <div>{title}</div>
            {step === 4
            ? null
            : <div>
                    <Progress step={step} clicked={clicked} />
                </div>
            }

        </div>
    )
}

const Progress = ({step, clicked}) => (
    <div style={{display: 'flex'}}>
        <div className={step === 1 ? [classes.ProgressItem, classes.Active].join(' ') : classes.ProgressItem}
            onClick={clicked} data-step="1">
            1</div>
        <div className={step === 2 ? [classes.ProgressItem, classes.Active].join(' ') : classes.ProgressItem}
            onClick={clicked} data-step="2">
            2</div>
        <div className={step === 3 ? [classes.ProgressItem, classes.Active].join(' ') : classes.ProgressItem}
            onClick={clicked} data-step="3">
            3</div>
    </div>
)


const StepOne = (props) => {
    const { formData, changed, formDataErrors: {stepOne: errors} } = props
    return (
        <div>
            <p style={{padding: '20px 20px 10px', fontSize: '0.9rem'}}>
                Ce formulaire est réservé aux individus domiciliés en Suisse et qui bénéficient d’une assurance. Vous pouvez demander un rendez-vous en ligne en remplissant le formulaire suivant ou par téléphone.
            </p>
            <div style={{padding: '0 20px 10px'}}>
                <div className={classes.FormRowWrapper}>
                    <div className={classes.FormRow}>
                        <div className={classes.Label}>Prénom</div>
                        <Input value={formData.firstName} name="firstName" changed={changed} styles={{borderColor: `${errors.firstNameError ? red : 'inherit'}`}}/>
                        {errors.firstNameError ? <RequiredError/> : null}
                    </div>
                    <div className={classes.FormRow}>
                        <div className={classes.Label}>Nom de famile</div>
                        <Input value={formData.lastName} name="lastName" changed={changed} styles={{borderColor: `${errors.lastNameError ? red : 'inherit'}`}}/>
                        {errors.lastNameError ? <RequiredError/> : null}
                    </div>
                </div>
            </div>
            <div style={{padding: '0 20px 10px'}}>
                <div className={classes.FormRowWrapper}>
                    <div className={classes.FormRow}>
                        <div className={classes.Label}>Email</div>
                        <Input value={formData.email} name="email" changed={changed} styles={{borderColor: `${errors.emailError ? red : 'inherit'}`}}/>
                        {errors.emailError ? <RequiredError/> : null}
                    </div>
                    <div className={classes.FormRow}>
                        <div className={classes.Label}>Téléphone</div>
                        <Input value={formData.phoneNumber} name="phoneNumber" changed={changed} styles={{borderColor: `${errors.phoneNumberError ? red : 'inherit'}`}}/>
                        {errors.phoneNumberError ? <RequiredError/> : null}
                    </div>
                </div>
            </div>
            <div style={{padding: '0 20px 10px'}}>
                <div className={classes.FormRowWrapper}>
                    <div className={classes.FormRow}>
                        <div className={classes.Label}>Addresse</div>
                        <Input value={formData.address} name="address" changed={changed} styles={{borderColor: `${errors.addressError ? red : 'inherit'}`}}/>
                        {errors.addressError ? <RequiredError/> : null}
                    </div>
                    <div className={classes.FormRow}>
                        <div className={classes.Label}>Pays</div>
                        <Input value={formData.country} name="country" changed={changed} styles={{borderColor: `${errors.countryError ? red : 'inherit'}`}}/>
                        {errors.countryError ? <RequiredError/> : null}
                    </div>
                </div>
            </div>
            <div style={{padding: '0 20px 10px'}}>
                <div className={classes.FormRowWrapper}>
                    <div className={classes.FormRow}>
                        <div className={classes.Label}>NPA</div>
                        <Input value={formData.postalCode} name="postalCode" changed={changed} styles={{borderColor: `${errors.postalCodeError ? red : 'inherit'}`}}/>
                        {errors.postalCodeError ? <RequiredError/> : null}
                    </div>
                    <div className={classes.FormRow}>
                        <div className={classes.Label}>Localité</div>
                        <Input value={formData.city} name="city" changed={changed} styles={{borderColor: `${errors.cityError ? red : 'inherit'}`}}/>
                        {errors.cityError ? <RequiredError/> : null}
                    </div>
                </div>
            </div>
            <div style={{padding: '0 20px 10px'}}>
                <div className={classes.FormRowWrapper}>
                    <div className={classes.FormRow}>
                        <div className={classes.Label}>Date de naissance</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Input placeholder="JJ" value={props.formData.dayOfBirth} name="day" changed={props.birthDateChange} styles={{borderColor: `${errors.dateOfBirthError ? red : 'inherit'}`, width: 60}}/> <span style={{padding: '0 4px'}}>/</span>
                            <Input placeholder="MM" value={props.formData.monthOfBirth} name="month" changed={props.birthDateChange} styles={{borderColor: `${errors.dateOfBirthError ? red : 'inherit'}`, width: 60}}/> <span style={{padding: '0 4px'}}>/</span>
                            <Input placeholder="AAAA" value={props.formData.yearOfBirth} name="year" changed={props.birthDateChange} styles={{borderColor: `${errors.dateOfBirthError ? red : 'inherit'}`, width: 100}}/>
                        </div>

                        {errors.dateOfBirthError
                            ? <div>
                                {formData.dateOfBirth.length < 10 && formData.dateOfBirth.length > 0
                                    ? <RequiredError text="Date non valide"/>
                                    : <RequiredError/>}
                            </div> : null}
                    </div>
                    <div className={classes.FormRow}>
                        <div className={classes.Label}>Gendre</div>
                        <div style={{display: 'flex'}}>
                            <div style={{flexBasis: '50%'}}><input name="gender" type="radio" value="Feminin" checked={formData.gender === 'Feminin'} onChange={changed}/> Feminin</div>
                            <div style={{flexBasis: '50%'}}><input name="gender" type="radio" value="Masculin" checked={formData.gender === 'Masculin'} onChange={changed}/> Masculin</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const StepTwo = (props) => {
    const { formData, changed, formDataErrors: { stepTwo: errors}, validateInsuranceNumber, checkLength } = props

    return (
        <div>

            <div style={{padding: '20px 20px 16px'}}>
                <div className={classes.FormRowWrapper}>
                    <div>
                        <div style={{paddingBottom: 10}} className={classes.Label}>Payement</div>
                        <div style={{display: 'flex', alignItems: 'baseline'}} >
                            <input type="radio" name="paymentMethod" value="soi-meme" checked={formData.paymentMethod === 'soi-meme'} onChange={changed}/>
                            <span style={{paddingLeft: 6}}>Je paye moi-même</span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'baseline'}} >
                            <input type="radio" name="paymentMethod" value="assurance" checked={formData.paymentMethod === 'assurance'} onChange={changed}/>
                            <span style={{paddingLeft: 6}}>Assurance maladie de base</span>
                        </div>
                    </div>
                </div>
            </div>

            { formData.paymentMethod === 'assurance'
                ? <div>
                    <div style={{padding: '0 20px 16px'}}>
                        <div >
                            <div className={classes.Label}>Nom de l'assureur</div>

                            <InsuranceInput
                                selectSavedInsurer={props.selectSavedInsurer}
                                value={props.formData.insurerName}
                                insurerSearched={props.insurerSearched}
                                error={errors.insurerNameError}
                            />
                        </div>
                    </div>
                    <div style={{padding: '0 20px 16px'}}>
                        <div style={{display: 'flex', alignItems: 'baseline', marginBottom: 10}} >
                            <input type="radio" name="familyDoctor" value="medecin-famille" checked={formData.familyDoctor === 'medecin-famille'} onChange={changed}/>
                            <span style={{paddingLeft: 6}}>J’ai souscrit au modèle “médecin de famille” ou “réseau de soins” de mon assurance.</span>
                        </div>

                        <div style={{display: 'flex', alignItems: 'baseline', marginBottom: 10}} >
                            <input type="radio" name="familyDoctor" value="medecin-libre" checked={formData.familyDoctor === 'medecin-libre'} onChange={changed}/>
                            <span style={{paddingLeft: 6}}>J'ai souscrit au modèle d'assurance de base, avec libre choix du médecin.</span>
                        </div>

                        <div style={{display: 'flex', alignItems: 'baseline', marginBottom: 10}} >
                            <input type="radio" name="familyDoctor" value="non-familier" checked={formData.familyDoctor === 'non-familier'} onChange={changed}/>
                            <span style={{paddingLeft: 6}}>Je ne suis pas familier avec mon modèle d’assurance de base dans lequel je suis souscrit.</span>
                        </div>

                    </div>
                </div>
                : null
            }


            <div>
                <div style={{padding: '0 20px 16px'}}>
                    <div className={classes.FormRowWrapper}>
                        <div style={{width:'100%'}}>
                            <div className={classes.Label}>Domaine de consultation</div>
                            <Select name="domaine" value={formData.domaine} changed={changed} styles={{width: '100%'}}>
                                <option value="Psychiatrie (Diagnostic/Médication)">Psychiatrie (Diagnostic/Médication)</option>
                                <option value="Psychothérapie/ Consultation Individuelle">Psychothérapie/ Consultation Individuelle</option>
                                <option value="Thérapie de couple">Thérapie de couple</option>
                                <option value="Thérapie familiale">Thérapie familiale</option>
                                <option value="Groupe thérapeutique">Groupe thérapeutique</option>
                                <option value="Je ne sais pas ...">Je ne sais pas ...</option>
                            </Select>
                        </div>
                    </div>
                </div>

                {props.canbook
                    ? <div style={{padding: '0 20px 16px'}}>
                        <div className={classes.Label}>Pour quelle(s) raison(s) souhaitez-vous consulter?</div>
                            <textarea rows="6"
                              style={{width:'100%', borderRadius: 2, borderColor: `${errors.messageError ? red : 'inherit'}`}}
                              className={classes.TextArea} name="message" value={formData.message} onChange={changed}>
                               </textarea>
                        {errors.messageError ? <RequiredError/> : null}
                    </div> : null }
                {!props.canbook
                    ? <div style={{padding: '0 20px 16px'}}>
                        <div className={classes.AlertMessageWrapper}>
                        Désolé, mais malheureusement nous sommes dans l'incapacité de prendre de nouveaux patients dans ce domaine en ce moment.
                    </div>
                </div> : null }
            </div>


            <div style={{padding: '20px', borderRadius: 2, color: 'rgb(2, 136, 209)', backgroundColor: 'rgb(225, 245, 254)', margin:'0 20px'}}>
                Si vous avez besoin d'assistance contactez-nous au <a href="tel:+41327214933"><strong>032 721 49 33</strong></a> ou par email <a href="mailto:admin@psy-scan.ch"><strong>admin@psy-scan.ch</strong></a>
            </div>


        </div>
    )

}

const ReviewStep = (props) => {
    const { formData, changed, formDataErrors: { stepThree: errors} } = props
    return (
        <div>
            <p style={{padding: '20px 20px 10px', fontSize: '0.9rem'}}>
                Confirmer vos informations et sélectionner le meilleur moyen de vous joindre ainsi que votre date idéale de consultation.
            </p>
            <div style={{padding: '0 20px 16px'}}>
                <h4>Votre contact</h4>
                <div className={classes.FormRowWrapper}>
                    <div className={classes.FormRow}>
                        <div style={{display: 'flex', justifyContent: 'space-between', borderTop: '1px dashed #E8E8E8', padding: '10px 0 6px'}}><span className={classes.Label}>Prénom</span><span>{formData.firstName}</span></div>
                        <div style={{display: 'flex', justifyContent: 'space-between', borderTop: '1px dashed #E8E8E8', padding: '10px 0 6px'}}><span className={classes.Label}>Nom de famille</span><span>{formData.lastName}</span></div>
                    </div>
                    <div className={classes.FormRow}>
                        <div style={{display: 'flex', justifyContent: 'space-between', borderTop: '1px dashed #E8E8E8', padding: '10px 0 6px'}}><span className={classes.Label}>Téléphone</span><span>{formData.phoneNumber}</span></div>
                        <div style={{display: 'flex', justifyContent: 'space-between', borderTop: '1px dashed #E8E8E8', padding: '10px 0 6px'}}><span className={classes.Label}>Email</span><span>{formData.email}</span></div>
                    </div>
                </div>
            </div>
            <div style={{padding: '0 20px 16px'}}>
                <div className={classes.FormRowWrapper}>
                    <div className={classes.FormRow}>
                        <div className={classes.Label}>Heure d'appel souhaitée</div>
                        <Select name="desiredCallTime" value={formData.desiredCallTime} changed={changed}>
                            <option value="9h -12h15">9h - 12h15</option>
                            <option value="13h30 - 18h30">13h30 - 18h30</option>
                            <option value="Peu importe">Peu importe</option>
                        </Select>
                    </div>
                    <div className={classes.FormRow}>
                        <div className={classes.Label}>Préférence de rendez-vous</div>
                        <Select name="desiredConsultationTime" value={formData.desiredConsultationTime} changed={changed}>
                            <option value="Premier disponible">Premier disponible</option>
                            <option value="Lundi - avant midi">Lundi - avant midi</option>
                            <option value="Lundi - après midi">Lundi - après midi</option>
                            <option value="Mardi - avant midi">Mardi - avant midi</option>
                            <option value="Mardi - après midi">Mardi - après midi</option>
                            <option value="Mercredi - avant midi">Mercredi - avant midi</option>
                            <option value="Mercredi - après midi">Mercredi - après midi</option>
                            <option value="Jeudi - avant midi">Jeudi - avant midi</option>
                            <option value="Jeudi - après midi">Jeudi - après midi</option>
                            <option value="Vendredi - avant midi">Vendredi - avant midi</option>
                            <option value="Vendredi - après midi">Vendredi - après midi</option>
                        </Select>
                    </div>
                </div>
            </div>

            <p style={{padding: '10px', margin: '10px 20px', fontSize: '0.8rem', borderRadius: 2, border:'1px solid #ff4b4b', color:'#ff4b4b', backgroundColor: '#fff'}}>
                Les demandes sont traitées par les admissions du PSI du lundi au vendredi à partir de 13:30.
                Vous serez directement contacté au moyen des coordonnées que vous nous avez indiquées dans un délai de 1 jour ouvrable pour recevoir un rendez-vous.
            </p>

            <div style={{padding: '10px 20px 16px'}}>
                <div style={{display: 'flex', alignItems: 'baseline'}}>
                    <input type="checkbox" name="termsAccepted" onChange={changed}/>
                    <span style={{paddingLeft: 6}}>J'accepte les <a style={{textDecoration: 'underline'}} href="/politique-de-confidentialite" target="_blank">conditions relatives à la transmission et à la confidentialité des données et les règles d'utilisation</a> liées à la demande de rendez-vous.</span>
                </div>
                <div style={{paddingLeft: 20, paddingBottom: 10}}>
                    {errors.termsAcceptedError ? <RequiredError/> : null}
                </div>

                <div style={{display: 'flex', alignItems: 'baseline'}}>
                    <ToolTip fontSize="0.8rem"><input type="checkbox" name="cancellationPolicy" onChange={changed}/>
                    <span style={{paddingLeft: 6}}>J'accepte la politique d'annulation de 48 heures</span></ToolTip>
                </div>
                <div style={{paddingLeft: 20, paddingBottom: 10}}>
                    {errors.cancellationPolicyError ? <RequiredError/> : null}
                </div>
            </div>
        </div>
    )
}

const ThankYou = () => (
    <div style={{padding: 20}}>

        <div style={{padding: 20}}>
            <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', width: '100%'}}>
                <img src={CheckIcon} alt="Icon Success" style={{width: 40, height: 40}}/>
            </div>
        </div>
        <p>
            Vos informations ont été soumises avec succès. Dans quelques instants, vous recevrez notre e-mail de confirmation automatique. S'il vous plaît, regarder dans votre dossier de courrier indésirable au cas où il se serait égaré. (Vous pouvez ajouter admin[arobase]psy-scan[point]ch pour empêcher le filtrage antispam.)
        </p>
        <p>
            La prochaine étape consiste à attendre notre appel. Nous vous contacterons dans les plus brefs délais pour fixer un rendez-vous.
        </p>
        <p>
            En fixant un rendez-vous, vous <strong><ToolTip fontSize="0.8rem">acceptez une politique d'annulation de 48 heures</ToolTip></strong>, c'est pour le premier rendez-vous seulement, par la suite les conditions du praticien s'appliquent.
        </p>
        <p>
            Si vous avez des questions n'hésitez pas à nous contacter au <a href="tel:+41327214933" style={{textDecoration: 'underline', fontWeight: 'bold'}}>032 721 49 33</a> du <strong>Lundi
            au Vendredi de 9h à 12h15 et 13h30 à 17h</strong>.
        </p>
        <p>
            <strong>Nous avons hâte de vous aider.</strong>
        </p>

    </div>
)

