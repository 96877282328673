import React, { Component } from 'react'
import Input from './Input'
import classes from './Forms.module.css'

const insurances =  [
    [1, "Agrisano"],
    [2, "Arcosana"],
    [3, "Assura"],
    [4, "Atupri"],
    [5, "Avenir"],
    [6, "Concordia"],
    [7, "CSS"],
    [8, "Easy Sana"],
    [9, "EGK-Gesundheitskasse"],
    [10, "Helsana"],
    [11, "Intras"],
    [12, "KPT / CPT"],
    [13, "Mutuel Assurance"],
    [14, "Philos"],
    [15, "Progrès"],
    [16, "ÖKK"],
    [17, "Sanagate"],
    [18, "Sanitas"],
    [19, "Supra"],
    [20, "Swica"],
    [21, "Sympany - Vivao"],
    [22, "Visana"],
]

export default class extends Component {
    state = {
        insurances: insurances,
        filteredInsurances: insurances,
        showDropDown: false
    }

    handleSearch = e => {
        const searched = e.target.value
        console.log('searched: ', searched)
        const initialInsurances = [...this.state.insurances]
        const results = initialInsurances.filter(item => {
            const text = item[1].split(' ').join('').toLowerCase()
            if (text.includes(searched.toLowerCase()) || text.toLowerCase().includes(searched.toLowerCase())) {
                return item
            }
        })
        this.setState({
            ...this.state,
            filteredInsurances: results
        }, () => this.props.insurerSearched(searched))

    }

    handleSelectInsurance = e => {
        const insurances = [ ...this.state.insurances ]
        const insurer = insurances.filter( item => item[0] === e.currentTarget.getAttribute('data-key'))[0]
        console.log(insurer)
        this.props.selectSavedInsurer(insurer[1])
    }

    render() {
        const { showDropDown } = this.state
        return (
            <div style={{position: 'relative'}}>
                <Input
                    blurred={() => setTimeout( () => this.setState({ showDropDown: false }), 500)}
                    focused={ () => this.setState({ showDropDown: true })}
                    changed={this.handleSearch}
                    value={this.props.value}
                />
                {showDropDown
                    ? <DropDown
                        { ...this.state }
                        selectInsurance={this.handleSelectInsurance}
                    />
                    : null
                }
                {this.props.error ? <RequiredError /> : null }
            </div>
        )
    }

}

const DropDown = (props) => {
    return (
        <div className={classes.SearchContactWrapper} style={{
            position: 'absolute',
            top: 34, left: 0,
            maxHeight: 100,
            overflowY: 'auto',
            width: '100%',
            zIndex: 100,
            backgroundColor: '#fff',
            border: '1px solid #d9d9d9',
            borderRadius: 3,
        }}>
            <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
                { props.filteredInsurances.length <= 0
                    ? <li className={classes.SearchResultList}>0 résultats</li>
                    : props.filteredInsurances.map( item => <li
                                className={classes.SearchResultList}
                                key={item[0]}
                                data-key={item[0]}
                                onClick={props.selectInsurance}
                            >
                            {item[1]}
                            </li>
                        )
                }
            </ul>
        </div>
    )
}
const red = '#ff6d6b'
const RequiredError = ({text, size}) => <div style={{color: red, fontSize: `${size ? size : 'inherit'}`}}><small>{text ? text : 'Ce champ est obligatoire'}</small></div>
